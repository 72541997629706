import { Skeleton } from 'antd';
import { createContext, useContext } from 'react';
import { useQuery } from './hooks/apollo';
import ErrorMessage from '../components/ErrorMessage';
import { myToolkitsAccess } from '../helpers/gqlQueries/me';
const ToolkitsAccessContext = createContext();
export default ToolkitsAccessContext;
export const ToolkitsAccessProvider = ({
  children
}) => {
  try {
    const {
      error,
      loading,
      data
    } = useQuery(myToolkitsAccess);
    if (loading) {
      return <Skeleton />;
    }
    if (error) {
      throw error;
    }
    return <ToolkitsAccessContext.Provider value={Object.seal({
      toolkitsResources: data.me.access.toolkitsResources
    })} children={children} />;
  } catch (e) {
    console.error(e);
    return <ErrorMessage message="Couldn't load toolkits access data. Refresh the page to try again" />;
  }
};
export const useToolkitsAccess = () => useContext(ToolkitsAccessContext);