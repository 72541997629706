import { Affix, Layout } from 'antd';
import Authed from '../_authed';
import AppHeader from '../AppHeader';
import ContextLoadingChecker from '../ContextLoadingChecker';
import ToolkitNavBar from './ToolkitNavBar';
import { ToolkitsIcon } from '../../components/images/NavIcons';
import { ToolkitsAccessProvider } from '../../lib/ToolkitsAccessContext';
const currentSection = {
  icon: <ToolkitsIcon />,
  name: 'Teacher Toolkits'
};
export default (({
  children
}) => <Authed>
		<Affix>
			<AppHeader currentSection={currentSection} />
			<ToolkitNavBar />
		</Affix>
		<ContextLoadingChecker>
			<ToolkitsAccessProvider>
				<Layout.Content style={{
        margin: 20,
        marginTop: 0,
        overflow: 'initial'
      }} children={children} />
			</ToolkitsAccessProvider>
		</ContextLoadingChecker>
	</Authed>);