import { Affix, Button, Layout } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useState } from 'react';
import InfoPanel from './InfoPanel';
import SideNavigation from './SideNavigation';
import Authed from '../_authed';
import AppHeader from '../AppHeader';
import ContextLoadingChecker from '../ContextLoadingChecker';
import { ReportsIcon } from '../../components/images/NavIcons';
import { ReportsAccessProvider } from '../../lib/ReportsAccessContext';
const currentSection = {
  icon: <ReportsIcon />,
  name: 'Reports'
};
export default (({
  children
}) => {
  const [isOpen, setIsOpen] = useState();
  return <Authed>
      <Affix>
        <AppHeader extraLeft={typeof isOpen === "boolean" && <Button className="header-menu-button" type="link" icon={<MenuOutlined />} shape="circle" size="large" onClick={() => setIsOpen(!isOpen)} />} currentSection={currentSection} />
      </Affix>
      <ContextLoadingChecker>
        <ReportsAccessProvider>
          <SideNavigation isOpen={isOpen} setIsOpen={setIsOpen} />
          <InfoPanel isOpen={isOpen} setIsOpen={setIsOpen} />
          <Layout.Content style={{
          margin: 10,
          marginTop: 0,
          overflow: 'initial'
        }} children={children} />
        </ReportsAccessProvider>
      </ContextLoadingChecker>
    </Authed>;
});