import { Skeleton } from 'antd';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import UiContextController from './UiContextController';
import { useSession } from '../../lib/hooks';
const Authed = ({
  children
}) => {
  const router = useRouter();
  const {
    user
  } = useSession();
  useEffect(() => {
    !user && router.pathname && router.push({
      pathname: `/login`
    });
  }, [router, user]);
  return user ? <UiContextController children={children} /> : <Skeleton />;
};
export default Authed;