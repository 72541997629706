import { Affix, Layout } from 'antd';
import AppHeader from './AppHeader';
import { HomeIcon } from '../components/images/NavIcons';
const currentSection = {
  icon: <HomeIcon />,
  name: 'Home'
};
export default (({
  children
}) => <>
    <Layout.Content style={{
    overflow: 'initial'
  }} children={children} />
  </>);