// prettier-ignore
import reports from './reports';
import toolkits from './toolkits';
import { anyone, nonStudent } from './access_defs';

export default {
  page: {
    ...Object.fromEntries(
      Object.entries({
        ...reports,
        ...toolkits,
      }).map(([k, acl]) => [k, (...args) => nonStudent(...args) && acl(...args)])
    ),
    'login': anyone,
    '': nonStudent, //home page
    'create-assignment': nonStudent, // redirect
    'gql': anyone,
    '403': anyone,
    '404': anyone,
    '500': anyone,
  },
};
