import { cloneElement } from 'react';
import AC from '../AccessControl';
import styles from './CustomButton.module.scss';
const CustomButton = ({
  url,
  type,
  iconURL,
  title,
  color,
  coverURL,
  secondaryColor,
  displayInaccessible
}) => {
  const style = {
    primary: {
      backgroundColor: color
    },
    full: {
      backgroundColor: color
    },
    card: {
      background: `linear-gradient(180deg, ${color}, ${secondaryColor})`
    },
    lined: {
      backgroundColor: `${color}20`,
      color: color
    }
  };
  const label = <div className={`${styles['custom-button-default']} ${styles[type]}`} style={style[type]}>
      {iconURL && <img src={iconURL} height={40} style={{
      backgroundColor: color
    }} alt={title} />}
      {coverURL && <img src={coverURL} />}
      <span className={styles.text} children={title} />
    </div>;
  return <AC.Link href={url} label={label} ifCant={displayInaccessible ? cloneElement(label, {
    style: {
      ...style[type],
      filter: 'grayscale(1)'
    }
  }) : null} data-sentry-element="unknown" data-sentry-component="CustomButton" data-sentry-source-file="CustomButton.jsx" />;
};
export default CustomButton;