import { CaretDownOutlined, DownOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Layout, Row, Space } from 'antd';
import Head from 'next/head';
import ReleaseNotes from './ReleaseNotes';
import AC from '../components/AccessControl';
import VideoPopUp from '../components/panels/VideoPopUp';
import { useSession } from '../lib/hooks';
import styles from './AppHeader.module.scss';
const {
  Header
} = Layout;
const userMenu = menuLinks => menuLinks.map((link, i) => ({
  key: `link_${i}`,
  label: <a href={link.href}>{link.title}</a>
}));
const separatorProps = {
  disabled: true,
  style: {
    color: '#000000',
    backgroundColor: '#efefef',
    cursor: 'default'
  }
};
const headerNav = menuLinks => menuLinks.map((link, i) => ({
  ...(link.type === 'Separator' ? separatorProps : {}),
  key: `link_${i}`,
  label: <a href={link.url}>{link.label}</a>,
  icon: link.imgSrc && <img src={link.imgSrc} className="menu-icon" style={{
    backgroundColor: link.buttonColor
  }} />
}));
const logo = <img className={styles["header-logo"]} src="/assets/f2b_logo.png" alt="Footsteps2Brilliance" />;
const AppHeader = ({
  extraLeft,
  extraRight,
  currentSection
}) => {
  const {
    user,
    menu
  } = useSession();
  return <Header className={styles["app-header"]} data-sentry-element="Header" data-sentry-component="AppHeader" data-sentry-source-file="AppHeader.jsx">
      <Head data-sentry-element="Head" data-sentry-source-file="AppHeader.jsx">
        <title key="title" children={`F2B - ${currentSection.name}`} />
      </Head>
      <Row type="flex" justify="space-between" align="middle" data-sentry-element="Row" data-sentry-source-file="AppHeader.jsx">
        <Col data-sentry-element="Col" data-sentry-source-file="AppHeader.jsx">
          <span style={{
          display: 'flex',
          height: 'auto',
          alignItems: 'center'
        }}>
            {extraLeft}
            <div className={styles["header-logo-container"]}>
              <AC.Link href={{
              pathname: '/'
            }} label={logo} ifCant={<AC.Link href={{
              pathname: '/reports'
            }} label={logo} ifCant={logo} />} data-sentry-element="unknown" data-sentry-source-file="AppHeader.jsx" />
            </div>
            <div className={styles["header-title"]}>
              <Dropdown menu={{
              items: headerNav(menu)
            }} overlayStyle={{
              minWidth: 'max-content'
            }} data-sentry-element="Dropdown" data-sentry-source-file="AppHeader.jsx">
                <Button onClick={e => e.preventDefault()} type="link" icon={currentSection?.icon} data-sentry-element="Button" data-sentry-source-file="AppHeader.jsx">
                  {currentSection?.name ?? <i>unnamed section</i>}
                  <DownOutlined data-sentry-element="DownOutlined" data-sentry-source-file="AppHeader.jsx" />
                </Button>
              </Dropdown>
            </div>
          </span>
        </Col>
        <Col align="right" data-sentry-element="Col" data-sentry-source-file="AppHeader.jsx">
          <Space size="large" data-sentry-element="Space" data-sentry-source-file="AppHeader.jsx">
            {extraRight}
            <VideoPopUp title="Welcome to the new Footsteps2Brilliance" videoUrl="https://www.youtube.com/embed/qgGOvXXRY_c" buttonTitle="F2B Central Tutorial" buttonProps={{
            className: 'antd-btn-pink',
            shape: 'round',
            size: 'default'
          }} type="primary" data-sentry-element="VideoPopUp" data-sentry-source-file="AppHeader.jsx" />
            {!user ? null : <AC.ButtonLink shape="round" icon={<UnorderedListOutlined />} label="Assignment Playlist" href={{
            pathname: '/toolkits/assignment-playlist'
          }} type="primary" className="antd-btn-purple" />}
            <ReleaseNotes data-sentry-element="ReleaseNotes" data-sentry-source-file="AppHeader.jsx" />
            <span>
              {user ? <Dropdown menu={{
              items: userMenu(process.env.userMenuLinks)
            }}>
                  <a onClick={e => e.preventDefault()}>
                    {`${user.first_name} ${user.last_name}`} <CaretDownOutlined />
                  </a>
                </Dropdown> : <AC.Link label="Log In" href={{
              pathname: '/login'
            }} />}
            </span>
          </Space>
        </Col>
      </Row>
    </Header>;
};
export default AppHeader;