// prettier-ignore
import { anyone, adminOnly, nonParent } from './access_defs';

export default Object.fromEntries(
  Object.entries({
    'toolkits/assignment-playlist': adminOnly,
    'toolkits/search-sim': adminOnly,
    'toolkits/search': anyone,
    'toolkits/alignments/[slug]': (_, { slug }) => `me { access { toolkitsResource(type: "Alignment", slug: "${slug}") } }`,
    'toolkits/books/[slug]': (_, { slug }) => `me { access { toolkitsResource(type: "Book", slug: "${slug}") } }`,
    'toolkits/games/[slug]': (_, { slug }) => `me { access { toolkitsResource(type: "Game", slug: "${slug}") } }`,
    'toolkits/lessons/[slug]': (_, { slug }) => `me { access { toolkitsResource(type: "Lesson", slug: "${slug}") } }`,
    'toolkits/library/[slug]': (_, { slug }) => `me { access { toolkitsResource(type: "Page", slug: "${slug}") } }`,
    'toolkits/printables/[slug]': (_, { slug }) => `me { access { toolkitsResource(type: "Printable", slug: "${slug}") } }`,
    'toolkits/units/[slug]': (_, { slug }) => `me { access { toolkitsResource(type: "Unit", slug: "${slug}") } }`,
    'toolkits/videos/[slug]': (_, { slug }) => `me { access { toolkitsResource(type: "Video", slug: "${slug}") } }`,
    'toolkits/[[...path]]': (_, { slug }) => `me { access { toolkitsResource(type: "LandingPage", slug: "${slug}") } }`,
  }).map(([k, acl]) => [k, (...args) => nonParent(...args) && acl(...args)])
);
