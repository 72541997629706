import { Col, Layout, Result, Row, Spin } from 'antd';
import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import { mySession } from '../../helpers/gqlQueries/me';
import { SessionProvider } from '../../lib/SessionContext';
import { AnalyticsProvider } from '../../lib/analytics';
import { useQuery } from '../../lib/hooks';
let lastKeepAlive = new Date();
const keepSessionAlive = () => {
  if (new Date() - lastKeepAlive > 60000) {
    fetch(process.env.keepSessionAliveURL, {
      credentials: 'include',
      mode: 'no-cors'
    });
    lastKeepAlive = new Date();
  }
};
const eventHandler = ['click', keepSessionAlive, {
  passive: true
}];
const SessionController = ({
  children
}) => {
  const {
    loading,
    error,
    data,
    previousData,
    refetch,
    client
  } = useQuery(mySession, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });
  const state = {
    refresh: refetch,
    menu: [],
    ...(!loading && !error && data?.me || {})
  };
  const userChanged = (() => {
    const [prevUserId, currUserId] = [previousData, data].map(d => d?.me?.user?.id);
    return !loading && prevUserId !== currUserId;
  })();
  if (userChanged) {
    client.resetStore();
  }
  useEffect(() => {
    if (state.user) {
      document.addEventListener(...eventHandler);
      Sentry.setUser({
        id: state.user.id,
        username: state.user.name
      });
      Sentry.setTag('user.type', state.user.type);
      return () => {
        document.removeEventListener(...eventHandler);
        Sentry.setUser(null);
        Sentry.setTag('user.type', null);
      };
    }
  }, [state]);
  if (userChanged || loading || error) {
    return <Layout style={{
      height: '100vh'
    }}>
				<Layout.Content style={{
        alignItems: 'center'
      }}>
					<Row type="flex" justify="center" align="middle" style={{
          height: '100%'
        }}>
						<Col span={8} style={{
            textAlign: 'center'
          }}>
							<Result icon={error ? undefined : <Spin size="large" />} status={error ? "error" : "info"} title={error ? "An error occurred during logging in. Reload the page to try again." : "Logging in..."} />
						</Col>
					</Row>
				</Layout.Content>
			</Layout>;
  }
  return <SessionProvider value={Object.seal(state)} data-sentry-element="SessionProvider" data-sentry-component="SessionController" data-sentry-source-file="SessionController.jsx">
			<AnalyticsProvider data-sentry-element="AnalyticsProvider" data-sentry-source-file="SessionController.jsx">
				<main children={children} />
			</AnalyticsProvider>
		</SessionProvider>;
};
export default SessionController;